import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ModalsDataTransferService } from 'src/app/core/services/modalService/modals-data-transfer.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { countryInterface } from 'src/app/core/interfaces/interfaces';
import { selectGetCounries } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { TranslocoModule } from '@ngneat/transloco';
import { TextFieldComponent } from '../../forms/text-field/text-field.component';
import { InnerLoaderComponent } from '../../animations/inner-loader/inner-loader.component';

@Component({
  selector: 'app-select-box-prefix-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslocoModule, TextFieldComponent, InnerLoaderComponent],
  templateUrl: './select-box-prefix-modal.component.html',
  styleUrl: './select-box-prefix-modal.component.scss',
})
export class SelectBoxPrefixModalComponent implements OnDestroy, OnInit {
  viewInit: boolean = false;
  delay: ReturnType<typeof setTimeout> = setTimeout(() => {});
  form: FormGroup = new FormGroup({});
  selectedCountry: countryInterface = {};
  unsubus$: Subject<boolean> = new Subject();
  selectCountries$: Observable<any> = this.store.select(selectGetCounries);
  countries: Array<countryInterface> = [];
  currentPrefix: string = '';
  filtredCountries: Array<countryInterface> = [];
  localPrefix: string = '';

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private modalDataTransferService: ModalsDataTransferService
  ) {
    this.currentPrefix = this.modalDataTransferService.getLocalPrefix();
    this.form = this.fb.group({
      countryName: [null],
    });
  }
  ngOnInit() {
    this.selectCountries$.pipe(takeUntil(this.unsubus$)).subscribe((res: { countries: Array<countryInterface> }) => {
      if (res.countries) {
        this.filtredCountries = res.countries;
        this.countries = res.countries;
        if (this.countries) {
          this.countries.forEach((el: any) => {
            if (el.value === this.localPrefix) {
              this.selectedCountry = el.value;
              return;
            }
          });
        }
      }
    });
    setTimeout(() => {
      this.viewInit = true;
    }, 1500);
  }
  search(value: any) {
    clearTimeout(this.delay);
    this.delay = setTimeout(() => {
      if (value.match(/^([+])/)) {
        value = value.replace(/^([+])/, '');
      }
      value = value?.toLowerCase();
      this.filtredCountries = this.countries.filter((item: any) => {
        return item?.title?.toLowerCase().startsWith(value) || item?.phoneCode?.toLowerCase().startsWith(value);
      });
    }, 500);
  }
  selectPrefix(item: countryInterface, isEmit: boolean = true) {
    if (item?.value) {
      this.currentPrefix = item?.value;
    }
    this.selectedCountry = item;
    if (isEmit) this.modalDataTransferService.selectPrefix(item);
  }
  findCountry(currentCountry: string) {
    return this.countries.filter((el: countryInterface) => el.value == currentCountry);
  }
  onSubmit() {
    if (this.filtredCountries.length == 1) {
      this.selectPrefix(this.filtredCountries[0]);
    }
  }
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
}
